export const SPIDER_MENU = {
  ID: "spiderMenu",
  SIMULATION_STEP_MS: 1000 / 50, // 50fps
  
  HEARTBEAT_INTERVAL_MS: { min: 1000, max: 5000 }, // how ofter in simulation steps it 'breaths' by generating random pushes
  HEARTBEAT_INTERVAL_NODE_LAG_MS: { min: 10, max: 200 }, // heartbeat occurs with a lag for the nodes (note at once for all)
  HEARTBEAT_POWER: { min: 2, max: 5 },

  CLICK_DURATION_MS: 250,
  FRICTION: 0.09,
  MIN_MOVEMENT_THRESHOLD: 0.01, // speed slower than this is set to zero
  
  HOME_MASS: 50,
  HOME_SIZE: 50,
  
  ITEM_MASS: 25,
  ITEM1_SIZE: 30,
  ITEM2_SIZE: 20,

  ITEM_RADIUS_X: 0.5, // put in a half-circle, the circle is bigger than the graph, nodes will hover at halfway
  ITEM_RADIUS_Y: 0.5,

  TARGET_POSITION_ATTRACTION_POWER: 10, // the designated position of nodes attract the nodes strongly
  HOME_NODE_ATTRACTOR_POWER: 1, // the home node attracts the child nodes
  ITEM_NODE_ATTRACTOR_POWER: 0.5, // the child nodes attract the home node
  POINTER_POKE_POWER: 3, // this one is mass independent, when the user pokes the nodes (it's a z<0 poke which makes the nodes larger)
  POINTER_CURSOR_TRACKING_POWER: 0.5, // when the home node follows the cursor on screen

  LABEL_WIDTH_PX: 100
}
