import React, { PropsWithChildren } from "react";
import { ExtenderBarHeader } from "./styled";
import { resolveEmpty } from "utils";

export interface ExtenderBarProps extends PropsWithChildren {
  id?: string;
  className?: string;
  title: string;
  subTitle?: string;
  isExpanded?: boolean;
  isHighlighted?: boolean; // default is isExpanded
  toggleExpanded?: () => void;
}

export const ExtenderBar = ({
  id, className = "", title, subTitle, isExpanded, isHighlighted, toggleExpanded, children
}: ExtenderBarProps) => {
  const isHighlightedResolved = resolveEmpty(isHighlighted, isExpanded);

  return (
    <div id={id} className={`disable-text-selection ${className}`}>
      <ExtenderBarHeader
        className={`workshop-entry-accordion pointer ${isExpanded ? "expanded" : "collapsed"}`}
        $isHighlighted={isHighlightedResolved}
        onClick={toggleExpanded}
      >
        <div className="font-medium">{isHighlightedResolved ? "-" : "+"}</div>
        <div>
          <div className="font-large lh-sm">{title}</div>
          <div className="font-medium lh-sm">{subTitle}</div>
        </div>
      </ExtenderBarHeader>

      {isExpanded && children}
    </div>
  );
};
