import { styled } from "styled-components";

export const WorkshopListContainer = styled.div`
  margin-top: 5em;
  margin-bottom: 5em;
  display: flex;
  flex-direction: column;
  gap: 3em;
`;

export const WorkshopListItem = styled.div`
  padding: 1em 0;
  font-size: 16px; /* hardcoded, not media dependent */
  
  li::marker {
    color: red;
  }
`;
