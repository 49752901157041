import { NavigateFunction } from "react-router-dom";
import { Vector } from "./types";

export function getResolvedPointerPagePosition(
  e: MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent
): Vector | undefined {
  const x = (e as MouseEvent).pageX || (e as TouchEvent).touches?.[0]?.pageX;
  const y = (e as MouseEvent).pageY || (e as TouchEvent).touches?.[0]?.pageY;

  return x !== undefined && y !== undefined ? { x, y, z: 0 } : undefined;
}

export const isTouchEvent = (
  e: MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent
) => {
  return !!(e as TouchEvent).touches;
};

// If anchor is not specified, parse the anchor from the url (takes last part of url path and tries to find an element with that id).
// Anchor is considered to be an element id and that element will be focused.
// If anchor is '/' then it will scroll to the top.
export const scrollToAnchor = (anchorElementId?: string, behavior: ScrollBehavior = "auto") => {
  window.setTimeout(
    () => {
      if (!anchorElementId) {
        const path = window.location.href.split("/");
        anchorElementId = path[path.length - 1];
      }

      document.getElementById(anchorElementId)?.scrollIntoView({ behavior });
    },
    1);
};

export const navigateTo = (url: string, navigate: NavigateFunction) => {
  navigate(url);
  scrollToAnchor(url === "/" ? "/" : undefined);
};
