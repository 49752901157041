import styled from "styled-components";

export const SpiderMenuNode = styled.div<{
  $rectClickable?: boolean;
  $titleClickable?: boolean;
  $dragged?: boolean;
  $zoom?: number; // default is 1
}>`
  position: absolute;
  z-index: 1;

  > div.rect {
    position: absolute;
    transform: rotate(45deg);
    ${({ $rectClickable }) => $rectClickable && "cursor: pointer;"}

    ${({ $dragged }) => $dragged
      ? "background-color: #FF4040;"
      : "background-color: red; &:hover { background-color: #FF6060; }"}
  }

  > div.title {
    position: absolute;
    white-space: nowrap;
    ${({ $titleClickable }) => $titleClickable ? "cursor: pointer;" : "pointer-events: none;"}
  }
`;
