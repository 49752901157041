import React from 'react';
import { MainLayout, PageHeading, SeparatorLine } from 'components';
import { BlogEntryList } from './components';

export const BlogPage = () => {  
  return (
    <MainLayout technologiesProps={{ showHeading: false }}>
      <PageHeading
        title="BLOG"
        subTitle="THERE ARE MANY TOPICS WE CANNOT GO LEAVE WITHOUT SAYING A WORD. HOPE OTHERS WILL NOT LEAVE EITHER."
      />
      
      <SeparatorLine title="blog" titleSuffix="post" />
      <BlogEntryList />
    </MainLayout>
  );
};
