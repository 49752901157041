import * as React from "react";
import { useEffect, useState } from "react";
import { CONFIG, SERVICES } from "data";
import { getResolvedPointerPagePosition, VECTOR_ZERO } from "utils";
import { SeparatorLine } from "components";

const {
  ENTERPRISE_ARCHITECTURE_MODERNIZATION, DATA_VISUALIZATION, ETLAST, SELFSERVICE_BI, WORKSHOPS, WEB_APPS
} = SERVICES;

const { UI: { maxSwipeDistanceOffAxisPx, minSwipeDistanceOnAxisPx } } = CONFIG;

const ALL_CLASSES = ["active", "visible", "hidden", "in-from-left", "in-from-right", "out-to-left", "out-to-right"];

export const OurServices_MobileView = () => {
  const [currentSlide, setCurrentSlide] = useState(-1);
  const [touchStart, setTouchStart] = useState(VECTOR_ZERO);
  const [touchEnd, setTouchEnd] = useState(VECTOR_ZERO);
  
  const showSlide = (index: number) => {
    const slides = document.querySelectorAll('.slide');
    const nextSlide = (index + slides.length) % slides.length;

    if (currentSlide >= 0) {
      slides[currentSlide].classList.remove(...ALL_CLASSES);
      slides[currentSlide].classList.add("visible", index > currentSlide ? 'out-to-left' : 'out-to-right');
    }

    slides[nextSlide].classList.remove(...ALL_CLASSES);    
    slides[nextSlide].classList.add("active", index > currentSlide ? 'in-from-right' : 'in-from-left');

    setCurrentSlide(nextSlide);

    window.setTimeout(
      () => {
        if (currentSlide >= 0) {
          slides[currentSlide].classList.remove(...ALL_CLASSES);
          slides[currentSlide].classList.add("hidden");
        }
      },
      250);
  }

  useEffect(
    () => {
      showSlide(0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleTouchStart = (e: React.TouchEvent) => {    
    const cursorPosition = getResolvedPointerPagePosition(e);

    if (cursorPosition) {
      setTouchStart(cursorPosition);
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const cursorPosition = getResolvedPointerPagePosition(e);

    if (cursorPosition) {
      setTouchEnd(cursorPosition);
    }
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    // we don't get touches here
    if (Math.abs(touchStart.y - touchEnd.y) < maxSwipeDistanceOffAxisPx) {
      if (touchEnd.x > touchStart.x + minSwipeDistanceOnAxisPx) {
        showSlide(currentSlide - 1);
      }
      else if (touchEnd.x < touchStart.x - minSwipeDistanceOnAxisPx) {
        showSlide(currentSlide + 1);
      }
    }
  };

  const swipeEvents: Pick<React.DOMAttributes<HTMLDivElement>, "onTouchStart" | "onTouchMove" | "onTouchEnd"> = {
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchEnd
  };

  return (
    <div className="mobile">
      <SeparatorLine title="what we" titleSuffix="do" />

      <div className="ourservices navigation container">
        <button
          id="prevBtn" className="nav-button"
          aria-label="Arrow to navigate to the previous 'Our Services' slide"
          onClick={() => showSlide(currentSlide - 1)}
        >
          <img src="/assets/images/mobile/mobile_home_left_arrow_active.svg" title="Slide to left" style={{ position: "relative", top: -2 }} />
        </button>
        
        <span id="pageNumber" className="page-number">
          {(currentSlide + 1).toString().padStart(2, '0')}
        </span>
        
        <button
          id="nextBtn"
          aria-label="Arrow to navigate to the next 'Our Services' slide"
          className="nav-button"
          onClick={() => showSlide(currentSlide + 1)}
        >
          <img src="/assets/images/mobile/mobile_home_right_arrow_active.svg" title="Slide to right" style={{ position: "relative", top: -2 }} />
        </button>
      </div>

      <div className="slider-container mobile" {...swipeEvents}>
        <div className="slide">
          <div className="wwd-header-container mobile-card">
            <div className="white-container">
              <div className="font-dinabpn font-small lh-sm">
                {ENTERPRISE_ARCHITECTURE_MODERNIZATION.title}
              </div>
              <div className="font-andele font-xsmall pb-3 pt-3 text-black lh-sm">
                {ENTERPRISE_ARCHITECTURE_MODERNIZATION.headline}
              </div>
              <div className="font-andele font-xsmall lh-sm">
                {ENTERPRISE_ARCHITECTURE_MODERNIZATION.content}
              </div>
            </div>
          </div>
        </div>

        <div className="slide hidden" {...swipeEvents}>
          <div className="datavis-container mobile-card">
            <div className="red-container">
              <div className="font-dinabpn x lh-sm">
                {DATA_VISUALIZATION.title}
              </div>
              <div className="font-andele font-xsmall pb-3 pt-3 lh-sm">
                {DATA_VISUALIZATION.headline}
              </div>
              <div className="font-andele font-xsmall lh-sm">
                {DATA_VISUALIZATION.content}
              </div>
            </div>
          </div>
        </div>

        <div className="slide hidden" {...swipeEvents}>
          <div className="etlast-container mobile-card">
            <div className="white-container">
              <div>
                <img className="etlast-logo pb-3 lh-sm" src="assets/images/technologies/etlast-small.png" title="ETLast product logo"></img>
              </div>
              <div className="text-black font-andele font-xsmall pb-3 lh-sm">
                {ETLAST.headline}
              </div>
              <div className="font-andele font-xsmall lh-sm">
                {ETLAST.content}
              </div>
            </div>
          </div>
        </div>

        <div className="slide hidden" {...swipeEvents}>
          <div className="selfservice-container mobile-card">
            <div className="red-container">
              <div className="font-dinabpn font-small lh-sm">
                {SELFSERVICE_BI.title}
              </div>
              <div className="font-andele font-xsmall pb-3 pt-3 lh-sm">
                {SELFSERVICE_BI.headline}
              </div>
              <div className="font-andele font-xsmall lh-sm">
                {SELFSERVICE_BI.content}
              </div>
            </div>
          </div>
        </div>

        <div className="slide hidden" {...swipeEvents}>
          <div className="workshops-container mobile-card">
            <div className="red-container">
              <div className="font-dinabpn font-small">
                {WORKSHOPS.title}
              </div>
              <div className="font-andele font-xsmall pt-3 lh-sm">
                {WORKSHOPS.content}
              </div>
            </div>
          </div>
        </div>

        <div className="slide hidden" {...swipeEvents}>
          <div className="appdev-container mobile-card">
            <div className="white-container">
              <div className="font-dinabpn font-small">
                {WEB_APPS.title}
              </div>
              <div className="font-andele font-xsmall pb-3 pt-3 text-black">
                {WEB_APPS.headline}
              </div>
              <div className="font-andele font-xsmall">
                {WEB_APPS.content}
              </div>
            </div>
          </div>
        </div>

        {/* Add more slides as needed */}
      </div>
    </div>
  );
};
