import * as React from "react";
import { MarqueeItem } from "./types";
import { PropsWithClassName } from "utils/types";

export interface MarqueeProps extends PropsWithClassName {
  items: MarqueeItem[];
  logoBaseUrl: string;
}

export const Marquee = ({ className = "", items, logoBaseUrl }: MarqueeProps) => {
  return (
    <div className={`marquee marquee--21 ${className}`}>
      {items.map(({ key, title, logoUrl, targetUrl }) => (
        <div key={key} className="marquee__item d-flex align-items-center justify-content-center">
          <img
            src={`${logoBaseUrl}/${logoUrl}`}
            title={title}
            alt={`Logo for ${title}`}
            className={targetUrl ? "pointer" : ""}
            onClick={() => targetUrl && window.open(targetUrl, "_blank")}
          ></img>
        </div>
      ))}
    </div>
  );
};
