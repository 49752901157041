import * as React from "react";
import { SimulationStepOptions, Size } from "utils/types";
import { SpiderGraph, SpiderGraphNode } from "./types";
import { getCurrentTime, isString, mapDictionaryValues, scaleRigidbody, simulationStep, vectorDistance } from "utils";
import { CONFIG, SPIDER_MENU } from "data";

const { SIMULATION_STEP_MS, MIN_MOVEMENT_THRESHOLD } = SPIDER_MENU;
const { TRACE_SIMULATION_DURATION } = CONFIG;

// map positions from old size to new size
export function scaleSpiderGraph(graph: SpiderGraph, canvasSize: Size): SpiderGraph {
  // scale nodes
  let nodes = mapDictionaryValues(graph.nodes, node => ({
    ...node,
    rigidbody: scaleRigidbody(node.rigidbody, canvasSize, graph.canvasSize)
  }));
  
  // now that new node positions are set, scale attractors
  nodes = mapDictionaryValues(nodes, node => ({
    ...node,
    rigidbody: {
      ...node.rigidbody,
      attractors: node.rigidbody.attractors.map(attractor =>
        attractor.type === "node-to-node" && isString(attractor.targetPosition)
          ? ({
            ...attractor,
            desiredDistance: vectorDistance(
              node.rigidbody.targetPosition!,
              graph.nodes[attractor.targetPosition].rigidbody.targetPosition!
            )
          })
          : attractor
      )
    }
  }));
  
  return {
    ...graph,
    canvasSize,
    nodes
  };
}

// we use nodeId for rigidbodyId too
export function spiderGraphSimulationStep(graph: SpiderGraph): SpiderGraph {
  const options: SimulationStepOptions = {
    simulationStepMs: SIMULATION_STEP_MS,
    getRigidbodyById: rigidbodyId => graph.nodes[rigidbodyId]?.rigidbody,
    minMovementThreshold: MIN_MOVEMENT_THRESHOLD
  };
  
  const startTime = getCurrentTime();

  try {
    return {
      ...graph,
      nodes: mapDictionaryValues(graph.nodes, node => ({
        ...node,
        rigidbody: simulationStep(node.rigidbody, options)
      }))
    };
  }
  finally {
    if (TRACE_SIMULATION_DURATION) {
      document.getElementById(`${graph.id}_simulationDuration`)!.innerText = `${new Date().getTime() - startTime}ms`;
    }
  }
}

export function updateSpiderGraphNodes(
  graph: SpiderGraph,
  update: (node: SpiderGraphNode, index: number) => SpiderGraphNode
): SpiderGraph {
  return {
    ...graph,
    nodes: mapDictionaryValues(graph.nodes, update)
  };
}
