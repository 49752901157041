import { useQuery, UseQueryResult } from "react-query";
import { concatPaths } from "utils";
import { CONFIG } from "data";
import { useMemo } from "react";
import { WorkshopEntryWithHtmlContent } from "pages/Workshops/types";
import { useWorkshopEntries } from "./useWorkshopEntries";

const { WORKSHOPS: { basePath, cacheDuration } } = CONFIG;

export interface UseWorkshopEntryProps {
  workshopEntryKey: string | undefined;
  enabled?: boolean;
}

// Returns blog entry with HTML content fetched from file
export function useWorkshopEntry({ workshopEntryKey, enabled = true }: UseWorkshopEntryProps): UseQueryResult<WorkshopEntryWithHtmlContent> {
  const { data: workshops } = useWorkshopEntries({ enabled });
  const workshop = workshopEntryKey && workshops && workshops.find(t => t.key === workshopEntryKey) || undefined;
  
  // useQuery helps to avoid simoultaneous calls and caches data
  const queryResult = useQuery(
    `useWorkshopEntry: ${workshopEntryKey}`,
    async () => {
      if (workshopEntryKey && workshop) {
        const response = await fetch(concatPaths([basePath, "html", workshop.contentUrl]));
        return await response.text();
      }
      else {
        return undefined;
      }
    },
    {
      enabled: enabled && !!workshop,
      staleTime: cacheDuration
    });

  // we need to return a memoized reference, but we have a calculated object, so we need to store it
  return useMemo<UseQueryResult<WorkshopEntryWithHtmlContent>>(
    () => ({
      ...queryResult,
      // @ts-ignore
      data: workshop && { ...workshop, contentHtml: queryResult.data }
    }),
    [workshop, queryResult]
  );
}
