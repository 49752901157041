import * as React from "react";
import { CONTENT, MENU_ITEMS } from "data";
import { MenuBar } from "components";
import { Copyright } from "./styled";
import { PropsWithClassName } from "utils/types";

const { COPYRIGHT } = CONTENT;

export type BottomMenuProps = PropsWithClassName;

export const BottomMenu = ({ className }: BottomMenuProps) => {
  return (
    <MenuBar menuType="bottom-bar" items={MENU_ITEMS} className={className}>
      <Copyright>{COPYRIGHT}</Copyright>
    </MenuBar>
  );
};
