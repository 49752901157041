import React, { useState } from 'react';
import { MainLayout, PageHeading, SeparatorLine } from 'components';
import { useWorkshopEntries } from 'hooks';
import { WorkshopItem } from './WorkshopItem';
import { WorkshopListContainer } from './styled';
import { scrollToAnchor } from 'utils';

export const WorkshopsPage = () => {
  const { data: workshops } = useWorkshopEntries();
  const [expandedKey, setExpandedKey] = useState("");
  const [highlightedKey, setHighlightedKey] = useState("");

  const handleToggleItem = (key: string) => {
    const elementId = `entry_${key}`;
    const getElement = () => document.getElementById(elementId)!;

    if (key !== expandedKey) {
      // open it
      setExpandedKey(key);
      setHighlightedKey(key);
      scrollToAnchor(elementId, "instant");      

      window.setTimeout(
        () => getElement().classList.add("workshop-item-open"),
        1);

      window.setTimeout(
        () => getElement().classList.remove("workshop-item-open"),
        500);
    }
    else {
      setHighlightedKey("");

      // close it
      window.setTimeout(
        () => getElement().classList.add("workshop-item-close"),
        1);

      window.setTimeout(
        () => {
          getElement().classList.remove("workshop-item-close");
          setExpandedKey("");
        },
        500);
    }
  };

  return (
    <MainLayout technologiesProps={{ showHeading: false }}>
      <PageHeading
        title="LEARNING POWER BI"
        subTitle="On site MS Power BI workshops"
      />
      
      <SeparatorLine title="Power BI" titleSuffix="workshops" />

      <div className="container">
        <div className="font-andele font-medium">
          We are ready to organize the workshops at your office or external site, customized for your company or team.{" "}
          We welcome any participants from beginners to experts, moreover, according to your needs, we deliver trainings{" "}
          specifically for developers. We prepare our presentations with customized content, including sample data typical{" "}
          to your industry and reporting solutions.
        </div>
        <div className="font-andele font-medium text-red" style={{ marginTop: "1em" }}>
          Optimal number of participants: 5-12
        </div>
      </div>

      <WorkshopListContainer className="container">
        {workshops && workshops.map(({ key }, index) => (
          <React.Fragment key={key}>
            {index === 1 && (
              <div className="workshops-large-white-square">
                <div>
                  <img src="/assets/images/workshops/learncin_left_deco_rect.svg" title="Large white square on the left" width="100%" />
                </div>
              </div>
            )}
            <WorkshopItem              
              workshopEntryKey={key}
              isExpanded={key === expandedKey}
              isHighlighted={key === highlightedKey}
              toggleExpanded={() => handleToggleItem(key)}
              prefetchContent
            />
          </React.Fragment>
        ))}
      </WorkshopListContainer>

      <div className="workshops-big-red-squre">
        <div>
          <img src="/assets/images/workshops/learncin_right_deco_rect.svg" title="Big red squre on the right" />
        </div>
      </div>

      <div className="container workshop-footer-container">
        <div className="row">
          <div className="col-5">
            <div className="font-dinabpn font-xlarge mb-5 lh-sm">WHY CHOOSE&nbsp;<span className="text-red">US?</span></div>
            <div className="d-flex justify-content-end">
              <div style={{ width: "20em" }}>
                <img src="/assets/images/workshops/learncin_choose_icon.svg" title="Checkmark in large square" width="100%" />
              </div>
            </div>
          </div>
          <div className="font-andele font-medium col-7">
            <p>Because we teach by doing and demonstrating, with more than one instructor if needed, solving specific use cases, even specific programming tasks to show the possibilities of reporting in Power&nbsp;BI.</p>
            <p>Our teachers are developers working with BI technologies every day, experienced in numerous projects and have current Power&nbsp;BI certifications.</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
