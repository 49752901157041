import { useEffect } from "react";

export interface UseScrollToTopProps {
  enabled?: boolean; // default is 'true'
}

// scroll to page top when loading
export const useScrollToTop = ({ enabled = true }: UseScrollToTopProps = {}) => {
  useEffect(
    () => {
      if (enabled) {
        window.setTimeout(
          () => window.scrollTo({ left: 0, top: 0, behavior: "instant" }),
          1);
      }
    },
    [enabled]);
};
