export const CONFIG = {
  TRACE_EVENTS: false,
  TRACE_SIMULATION_DURATION: false,

  PROJECTION: {
    projectionPlaneDistance: 200, // for 3d projection
    perspectivicProjection: false
  },

  UI: {
    minSwipeDistanceOnAxisPx: 20, // user must swipe at least this much in the direction of the swipe
    maxSwipeDistanceOffAxisPx: 40, // user must swipe less than this much in the cross direction of the swipe; otherwise it's not considered as a swipe action
    menuBarAutoHideMs: 5000,
    elementSizePollingIntervalMs: 500
  },

  BLOG: {
    basePath: "/assets/blog",
    rootFile: "blog.json",
    cacheDuration: Infinity,
    prefetchThumbnails: 3
  },

  WORKSHOPS: {
    basePath: "/assets/workshops",
    rootFile: "workshops.json",
    cacheDuration: Infinity
  },

  PARTNERS: {
    basePath: "/assets/partners",
    rootFile: "partners.json",
    cacheDuration: Infinity
  },

  TECHNOLOGIES: {
    basePath: "/assets/technologies",
    rootFile: "technologies.json",
    cacheDuration: Infinity
  }
};
