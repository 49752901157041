import { useEffect, useRef, useState } from "react";
import { CONFIG } from "data";
import { isArray } from "utils";

const { UI: { elementSizePollingIntervalMs } } = CONFIG;

export type UseElementSizeCallback = (bounds: DOMRect, element: HTMLElement) => void;

export interface UseElementSizeProps {
  elementId: string | string[];
  enabled?: boolean; // default is true

  onResize: UseElementSizeCallback;
  initOnResize?: boolean; // call onResize() during initialization, not only when resizing occurs
}

export const useElementSize = ({ onResize, elementId, enabled = true, initOnResize }: UseElementSizeProps) => {
  const [currentSizes, setCurrentSizes] = useState<Record<string, DOMRect>>({}); // [elementId, client bounds]
  const currentSizesRef = useRef(currentSizes);
  currentSizesRef.current = currentSizes;

  const handleResize = (forced?: boolean) => {
    const elements = (isArray(elementId) ? elementId : [elementId])
      .map(t => document.getElementById(t))
      .filter(t => t)
      .map(t => t!);

    if (elements.length) {
      let dirty = false;

      for (const element of elements) {
        const size = element.getBoundingClientRect();
        const currentSize = currentSizesRef.current[element.id];

        if (forced || (size && (size.width !== currentSize?.width || size.height !== currentSize?.height))) {
          currentSizes[element.id] = size;
          onResize(size, element);
          dirty = true;
        }
      }

      if (dirty) {
        setCurrentSizes(currentSizes);
      }
    }
  };

  useEffect(
    () => {
      // Initialize
      if (enabled) {
        const timer = window.setInterval(
          () => handleResize(),
          elementSizePollingIntervalMs);

        if (initOnResize) {
          handleResize(true);
        }

        // Finalize
        return () => {
          window.clearInterval(timer);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enabled]);
};
