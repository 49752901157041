export const trimLeft = (s: string, remove: string) => {
  if (!s || !remove || remove.length > s.length) {
    return s;
  }

  let i = 0;

  while (i < s.length && s.startsWith(remove, i)) {
    i += remove.length;
  }

  return i === 0 ? s : i > s.length ? "" : s.substring(i);
};

export const trimRight = (s: string, remove: string) => {
  if (!s || !remove || remove.length > s.length) {
    return s;
  }

  let i = s.length;

  while (i >= 0 && s.startsWith(remove, i - remove.length)) {
    i -= remove.length;
  }

  return i >= s.length ? s : i < 0 ? "" : s.substring(0, i);
};

export const trim = (s: string, remove: string) => {
  return trimLeft(trimRight(s, remove), remove);
}

export const concatPaths = (s: string[], separator = "/") => {
  return s.map(t => trimRight(t, separator)).join(separator);
};
