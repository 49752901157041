import { isNumber, isString } from "./common";
import { Range } from "./types";

// returns [min, max)
export function random({ min, max }: Range<number>): number {
  return (Math.random() * (max - min)) + min;
}

export const eliminateNaN = (value: number, ifNan: number) => {
  return isNaN(value) ? ifNan : value;
};

export const deg2Rad = (deg: number) => {
  return deg * Math.PI / 180;
}

export const rad2Deg = (rad: number) => {
  return rad * 180 / Math.PI;
}

// returns undefined if value is NaN
export function tryParseFloat(value: unknown): number | undefined {
  if (value === undefined || value === null || value ==="") {
    return undefined;
  }
  else if (isNumber(value)) {
    return isNaN(value) ? undefined : value;
  }
  else if (isString(value)) {
    try {
      const number = parseFloat(value);
      return isNumber(number) && !isNaN(number) ? number : undefined;
    } catch {
      return undefined;
    }
  }
}
