import * as React from "react";
import { CSSProperties, HTMLAttributes } from "react";
import { SpiderGraph, SpiderGraphEdge, SpiderGraphNode } from "./types";
import { SpiderMenuNode } from "./SpiderMenu.styled";
import { CONFIG, SPIDER_MENU } from "data";
import { SvgLine } from "components";
import { getCenterPoint, project3dTo2d } from "utils";

const { LABEL_WIDTH_PX } = SPIDER_MENU;

export function getNodeTitlePositionStyle({ size, titleAlign }: SpiderGraphNode, scale: number): CSSProperties {
  switch (titleAlign) {
    case "inside":
      return {
        textAlign: "center",
        left: -LABEL_WIDTH_PX * scale / 2,
        top: "-0.83em",
        width: LABEL_WIDTH_PX * scale,
        fontSize: `${scale}em`
      };

    case "left":
      return {
        textAlign: "right",
        right: `calc(${size * 0.8}px + 0.5em)`,
        top: "-0.8em",
        fontSize: `${scale}em`
      };

    case "right":
      return {
        textAlign: "left",
        left: `calc(${size * 0.8}px + 0.5em)`,
        top: "-0.8em",
        fontSize: `${scale}em`
      };

    // bottom
    default:
      return {
        textAlign: "center",
        left: -LABEL_WIDTH_PX * scale / 2,
        top: size,
        width: LABEL_WIDTH_PX * scale,
        fontSize: `${scale}em`
      };
  }
}

export const renderSpiderGraphNodes = (
  graph: SpiderGraph,
  options?: {
    draggedNodeId?: string,
    attributes?: (node: SpiderGraphNode, elementId: string) => HTMLAttributes<HTMLDivElement> | undefined,
    rectAttributes?: (node: SpiderGraphNode, elementId: string) => HTMLAttributes<HTMLDivElement> | undefined,
    titleAttributes?: (node: SpiderGraphNode, elementId: string) => HTMLAttributes<HTMLDivElement> | undefined
  }
) => {
  const viewportCenter = getCenterPoint(graph.canvasSize);

  return Object.values(graph.nodes).map(node => {
    const elementId = `${graph.id}_${node.nodeId}`;
    const position = project3dTo2d(node.rigidbody.position, viewportCenter);
    const scale = position.z;
    const size = node.size * scale;
    
    return (
      <SpiderMenuNode
        id={elementId}
        key={node.nodeId}
        $rectClickable={!!node.targetUrl}
        $titleClickable={!!node.targetUrl && node.titleAlign !== "inside"}
        $dragged={node.nodeId === options?.draggedNodeId}
        style={{
          left: position.x,
          top: position.y
        }}
        aria-label={`Spider menu node with title ${node.title}${node.targetUrl ? ` redirecing to ${node.targetUrl}` : ""}`}
        {...options?.attributes?.(node, elementId)}
      >
        <div
          className="rect"
          style={{
            left: -size / 2,
            top: -size / 2,
            width: size,
            height: size
          }}
          {...options?.rectAttributes?.(node, elementId)}
        ></div>

        {node.title && (
          <div
            className="title"
            style={getNodeTitlePositionStyle(node, scale)}
            {...options?.titleAttributes?.(node, elementId)}
          >
            {node.title}
          </div>
        )}
      </SpiderMenuNode>
    );
  });
};

export const renderSpiderGraphEdges = (
  graph: SpiderGraph,
  options?: {
    attributes?: (edge: SpiderGraphEdge, elementId: string) => HTMLAttributes<HTMLDivElement> | undefined
  }
) => {
  const viewportCenter = getCenterPoint(graph.canvasSize);

  return graph.edges.map(edge => {
    const key = `${edge.fromNodeId}-${edge.toNodeId}`;
    const elementId = `${graph.id}_${key}`;
    const fromNode = graph.nodes[edge.fromNodeId];
    const toNode = graph.nodes[edge.toNodeId];

    return fromNode && toNode
      ? (
        <SvgLine
          id={elementId}
          key={key}
          {...options?.attributes?.(edge, elementId)}
          color={edge.color || "white"}
          points={[
            project3dTo2d(fromNode.rigidbody.position, viewportCenter),
            project3dTo2d(toNode.rigidbody.position, viewportCenter)
          ]}
        />
      )
      : null;
  });
};
