import React from 'react';
import { BlogEntryOverview } from './BlogEntryOverview';
import { useBlogEntries } from 'hooks';

export const BlogEntryList = () => {
  const { data: blogEntries } = useBlogEntries();

  return blogEntries && blogEntries.map(({ key }, index) => (
    <BlogEntryOverview key={key} blogEntryKey={key} alternateRow={!!(index % 2)} />
  ));
};
