import React from 'react';
import { Link } from 'react-router-dom';
import { BlogDate, BlogHeadline, BlogOverview, BlogThumbnail, BlogTitle } from '../styled';
import { concatPaths, formatDate } from 'utils';
import { useBlogEntries, useBlogEntry } from 'hooks';
import { CONFIG } from 'data';

const { BLOG: { basePath } } = CONFIG;

export interface BlogEntryBlogEntryOverviewProps {
  blogEntryKey: string;
  alternateRow?: boolean;
}

export const BlogEntryOverview = ({ blogEntryKey, alternateRow }: BlogEntryBlogEntryOverviewProps) => {
  const blogListItem = useBlogEntries().data?.find(t => t.key === blogEntryKey);
  const { key, title, date, headline, thumbnailUrl } = blogListItem || {};

  return blogListItem && key && thumbnailUrl && (
    <div id={`entry_${key}`} className="container blog-entry">
      <div className="row" style={{
        // this is 'row' in bootstrap, we set here 'row' or 'row-reverse' but in main.css we set it to 'column !important' for mobile view
        flexDirection: alternateRow ? "row-reverse" : "row"
      }}>
        {/* Headline */}
        <BlogOverview className="col-6 blog-entry-content">
          <BlogTitle>
            <Link to={`/blog/${key}`}>
              {title}
            </Link>
          </BlogTitle>
          <BlogDate>
            {formatDate(date!)}
          </BlogDate>
          <BlogHeadline>
            {headline}
          </BlogHeadline>
        </BlogOverview>

        {/* Thumbnail */}
        <BlogThumbnail className="col-6 blog-entry-thumbnail">
          <div>
            <img
              src="/assets/images/blog_footer_rect.svg"
              title="Red rotated square decoration in the background"
            />

            <Link to={concatPaths(["/blog", key])}>
              <img
                src={concatPaths([basePath, "images", thumbnailUrl])}
                title={`Thumbnail for blog entry '${title}'`}
              />
            </Link>
          </div>
          <div>
          </div>
        </BlogThumbnail>
      </div>
    </div>
  );
};
