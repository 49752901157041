import { useQuery, UseQueryResult } from "react-query";
import { concatPaths, isString } from "utils";
import { CONFIG } from "data";
import { BlogEntry } from "pages/Blog/types";

const { BLOG: { basePath, rootFile, cacheDuration } } = CONFIG;

export interface UseBlogEntriesProps {
  enabled?: boolean;
}

export function useBlogEntries({ enabled = true }: UseBlogEntriesProps = {}): UseQueryResult<BlogEntry[]> {
  // useQuery helps to avoid simoultaneous calls and caches data
  return useQuery<BlogEntry[]>(
    "useBlogEntries",
    async () => {
      const response = await fetch(concatPaths([basePath, rootFile]));
      const json = await response.text();

      // parse dates
      const data = JSON.parse(
        json,
        (_, value) => isString(value) && value.match(/^\d{4}-\d{1,2}-\d{1,2}$/) ? new Date(value) : value
      );
      
      return data;
    },
    {
      enabled,
      staleTime: cacheDuration      
    });
}
