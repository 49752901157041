import React from 'react';
import { MainLayout } from 'components';
import { BlogEntryDetailed } from './components/BlogEntryDetailed';
import { useScrollToTop } from 'hooks';

export interface BlogEntryPageProps {
  blogEntryKey: string;
}

export const BlogEntryPage = ({ blogEntryKey }: BlogEntryPageProps) => {  
  useScrollToTop();
  
  return (
    <MainLayout technologiesProps={{ showHeading: false }}>
      <BlogEntryDetailed blogEntryKey={blogEntryKey} />
    </MainLayout>
  );
};
