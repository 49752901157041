import { MenuItem } from "components/MenuBar/types";

// Update .navBarMenuExpanding and .navBarMenuCollapsing in main.css when adding new menu items
export const MENU_ITEMS: MenuItem[] = [
  {
    key: "home",
    title: "Home",
    targetUrl: "/"
  },
  {
    key: "weare",
    title: "We are",
    targetUrl: "/weare"
  },
  {
    key: "services",
    title: "Our Services",
    targetUrl: "/services",
    spiderMenuRadius: 1.5,
    spiderMenuAngleOffset: 10
  },
  {
    key: "technologies",
    title: "Technologies",
    targetUrl: "/technologies"
  },
  {
    key: "partners",
    title: "Our Partners",
    targetUrl: "/partners"
  },
  {
    key: "workshops",
    title: "Workshops",
    targetUrl: "/workshops",
    spiderMenuRadius: 1.5,
    spiderMenuAngleOffset: 0
  },
  {
    key: "blog",
    title: "Blog",
    targetUrl: "/blog",
    spiderMenuRadius: 1.5,
    spiderMenuAngleOffset: -10    
  },
  {
    key: "contact",
    title: "Contact",
    targetUrl: "/contact",
    spiderMenuRadius: 0.9,
    spiderMenuAngleOffset: 5
  }
];
