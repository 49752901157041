import React, { ReactNode } from "react";

export interface PageHeadingProps {
  title: string;
  subTitle?: string;
}

export const PageHeading = ({ title, subTitle }: PageHeadingProps) => {
  return (
    <div className="container page-heading-container">
      <div className="text-center">
        <div className="text-uppercase font-dinabpn font-xlarge lh-sm">{title}</div>
        {subTitle && (
          <div className="text-red font-dinabpn font-medium lh-sm mt-3">
            {subTitle}
          </div>
        )}
      </div>
    </div>
  );
};
