export function mapDictionaryValues<In, Out>(
  dict: Record<string, In>,
  map: (value: In, index: number, key: string) => Out
): Record<string, Out> {
  const result: Record<string, Out> = {};

  Object.entries(dict).forEach(([key, value], index) => {
    result[key] = map(value, index, key);
  });

  return result;
}

export function convertArrayToDictionary<Item, Value>(
  array: Item[],
  getEntry: (item: Item, index: number) => [string, Value]
): Record<string, Value> {
  const result: Record<string, Value> = {};

  array.forEach((item, index) => {
    const [key, value] = getEntry(item, index);
    result[key] = value;
  });

  return result;
}
