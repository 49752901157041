import React from 'react';
import { Introduction, OurServices, WeAre } from './sections';
import { MainLayout, PageHeading, SpiderMenu } from 'components';
import { PrefetchBlogEntryThumbnails } from 'pages/Blog';

export const HomePage = () => {
  // TopMenu is only displayed in mobile view because for desktop view we have the SpiderMenu
  // The MainLayout loads partners.json and technologies.json and all logo images.
  return (
    <MainLayout topMenuProps={{ desktopMenu: false }}>
      <SpiderMenu className="desktop" />
      <PageHeading title="YES, WE DO LOVE DATA" subTitle="data is the RAW material of success" />

      <Introduction />
      <WeAre />
      <OurServices />
      
      {/* 
        This prefetches blog.json and first few blog HTML files. 
        blog.json is also loaded by AppRoutes to generate routes for blog entries.
      */}
      <PrefetchBlogEntryThumbnails />
    </MainLayout>
  );
};
