export function isString(obj: unknown): obj is string {
  return typeof obj === 'string' || obj instanceof String;
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isDate(value: unknown): value is Date {
  return !!(value && (value as Date).getTime && (value as Date).getDate);
}

export function isArray(obj: unknown): obj is any[] {
  return Array.isArray(obj);
}

// Does not consider falsy values empty (zero, empty string). Considers NaN empty.
export function isEmpty(value: unknown): value is undefined | null | '' {
  return value === undefined || value === null || value === '' || (isNumber(value) && isNaN(value));
}

export function resolveEmpty<Value, EmptyValue>(value: Value | undefined | null, valueIfEmpty: EmptyValue): Value | EmptyValue {
  return isEmpty(value) ? valueIfEmpty : value;
}
