import React from "react";
import { useNavigate } from "react-router-dom";

export interface NavigateBackArrowProps {
  // if there is no history this will be used; if not set and there is no history, the back arrow won't be rendered
  defaultBackUrl?: string; 

  backUrl?: string; // if set, always this will be used
}

export const NavigateBackArrow = ({ defaultBackUrl, backUrl }: NavigateBackArrowProps) => {
  const navigate = useNavigate();

  return (window.history.length > 1 || defaultBackUrl || backUrl) && (
    <div className="container" style={{ marginBottom: "4em", paddingLeft: 0 }}>
      <button
        className="nav-button"
        aria-label="Arrow to navigate to the previous 'Our Services' slide"
        onClick={() => {
          if (backUrl) {
            navigate(backUrl);
          }
          else if (window.history.length > 1) {
            window.history.back();
          } else if (defaultBackUrl) {
            navigate(defaultBackUrl);
          }
        }}
      >
        <img src="/assets/images/mobile/mobile_home_left_arrow_active.svg" title="Slide to left" style={{ position: "relative", top: -2 }} />
        &nbsp;Back
      </button>
    </div>
  );
};
