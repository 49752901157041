import * as React from "react";
import { SeparatorLine } from "components/SeparatorLine";

export const WeAre = () => {
  return (
    <div id="weare">
      <SeparatorLine title="we" titleSuffix="are" />
      
      <div className="container">
        <div className="text-uppercase font-dinabpn font-large lh-sm we-are-header">
          WE ARE PROUD OF OUR SENIOR TEAM AND EXPERTISE. WE COMBINE THE <span className="text-red">FLEXIBILITY</span> OF SMALL COMPANIES WITH THE <span className="text-red">PROFESSIONALISM</span> OF BIGGER ONES.
        </div>
        <div className="font-andele font-medium lh-sm">
          As a collaboration of experts with vast experience in the field of technology, processes, enterprises and IT overall,
          we have proudly delivered more than a hundred successful projects.
        </div>
      </div>
    </div>
  );
};
