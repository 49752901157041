import * as React from "react";
import { Marquee } from "components";
import { usePartners } from "hooks";
import { CONFIG } from "data";
import { concatPaths } from "utils";

const { PARTNERS: { basePath } } = CONFIG;

export const Partners = () => {
  const { data: partners } = usePartners();

  return (
    <div id="partners" className="partners-container">
      <div className="container">
        <div className="text-center font-dinabpn font-large">
          OUR PARTNERS:
        </div>

      </div>

       <div className="partners">
        <div className="partnerlogos-container">
          <Marquee
            logoBaseUrl={concatPaths([basePath, "images"])}
            items={partners || []} />
        </div>
      </div>
    </div>
  );
};
  