import * as React from "react";
import { SERVICES } from "data";
import { SeparatorLine } from "components/SeparatorLine";

const { MOTTO, ENTERPRISE_ARCHITECTURE_MODERNIZATION, DATA_VISUALIZATION, ETLAST, SELFSERVICE_BI, WORKSHOPS, WEB_APPS } = SERVICES;

export const OurServices_DesktopView = ()=>{
  return (
    <div className="ourservices wwdo-container desktop">
      <SeparatorLine title="what we" titleSuffix="do" />
      
      <div className="ourservices-container">
        <div className="wwd-header">
          <div className="font-dinabpn font-large mb-5 lh-sm">
            {MOTTO}
          </div>
        </div>

        <div className="row col flex-tarto">

          <div className="wwd-header-container o1">
            <div className="white-container animated animate__fadeInLeft">
              <div className="font-dinabpn font-small lh-sm">
                {ENTERPRISE_ARCHITECTURE_MODERNIZATION.title}
              </div>

              <div className="font-andele font-xsmall pb-3 pt-3 text-black lh-sm">
                {ENTERPRISE_ARCHITECTURE_MODERNIZATION.headline}
              </div>

              <div className="font-andele font-xsmall lh-sm">
                {ENTERPRISE_ARCHITECTURE_MODERNIZATION.content}
              </div>
            </div>
          </div>

          <div className="datavis-container o2">
            <div className="red-container animated animate__fadeIn">
              <div className="font-dinabpn font-small lh-sm">
                {DATA_VISUALIZATION.title}
              </div>
              <div className="font-andele font-xsmall pb-3 pt-3 lh-sm">
                {DATA_VISUALIZATION.headline}
              </div>
              <div className="font-andele font-xsmall lh-sm">
                {DATA_VISUALIZATION.content}
              </div>
            </div>
          </div>

          <div className="etlast-container o3">
            <div className="white-container animated animate__fadeInRight">
              <div>
                <img className="etlast-logo pb-3 lh-sm" src="/assets/images/technologies/etlast-small.png" title="ETLast product logo" />
              </div>

              <div className="text-black font-andele font-xsmall pb-3 lh-sm">
                {ETLAST.headline}
              </div>

              <div className="font-andele font-xsmall lh-sm">
                {ETLAST.content}
              </div>
            </div>
          </div>

          <div className="empty-container empty-container-1 o4"></div>

          <div className="selfservice-container o5">
            <div className="red-container animated animate__fadeInUp">
              <div className="font-dinabpn font-small lh-sm">
                {SELFSERVICE_BI.title}
              </div>

              <div className="font-andele font-xsmall pb-3 pt-3 lh-sm">
                {SELFSERVICE_BI.headline}
              </div>

              <div className="font-andele font-xsmall lh-sm">
                {SELFSERVICE_BI.content}
              </div>
            </div>
          </div>

          <div className="workshops-container o6">
            <div className="red-container animated animate__fadeInRight">
              <div className="font-dinabpn font-small lh-sm">
                {WORKSHOPS.title}
              </div>

              <div className="font-andele font-xsmall pt-3 lh-sm">
                {WORKSHOPS.content}
              </div>
            </div>
          </div>

          <div className="empty-container empty-container-2 o7"></div>
          <div className="empty-container empty-container-3 o8"></div>

          <div className="appdev-container o9">
            <div className="white-container animated animate__fadeInUp">
              <div className="font-dinabpn font-small">
                {WEB_APPS.title}
              </div>

              <div className="font-andele font-xsmall pb-3 pt-3 text-black">
                {WEB_APPS.headline}
              </div>

              <div className="font-andele font-xsmall">
                {WEB_APPS.content}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
