import React from 'react';
import { useBlogEntries } from 'hooks';
import { concatPaths } from 'utils';

export const PrefetchBlogEntryThumbnails = () => {
  const { data: blogEntries } = useBlogEntries();

  return (
    <div className="invisible hidden-minimized">
      {blogEntries && blogEntries.slice(0, 5).map(({ key, thumbnailUrl }) => (
        <img
          key={key}
          src={concatPaths(["/assets/blog/images", thumbnailUrl])}
          title={`Hidden pre-fetched blog image for entry ${key}`}
        />
      ))}
    </div>
  );
};
