import React from 'react';
import { useWorkshopEntries, useWorkshopEntry } from 'hooks';
import { ExtenderBar } from 'components';
import { WorkshopListItem } from './styled';

export interface WorkshopItemProps {
  workshopEntryKey: string;
  isExpanded?: boolean;
  isHighlighted?: boolean; // default is isExpanded
  prefetchContent?: boolean; // pre-fetch content even if the expander is closed
  toggleExpanded?: () => void;
}

export const WorkshopItem = ({
  workshopEntryKey, isExpanded, isHighlighted, toggleExpanded, prefetchContent
}: WorkshopItemProps) => {
  const workshopListItem = useWorkshopEntries().data?.find(t => t.key === workshopEntryKey);
  const { data: workshopEntry } = useWorkshopEntry({ workshopEntryKey, enabled: isExpanded || prefetchContent });

  const { title, subTitle } = workshopListItem || {};
  const { contentHtml } = workshopEntry || {};

  return title && (
    <ExtenderBar {...{
      id: `entry_${workshopEntryKey}`,
      className: "workshop-list-item",
      title,
      subTitle,
      isExpanded,
      isHighlighted,
      toggleExpanded
    }} >
      {isHighlighted && (
        <div className="workshops-item-white-square">
          <div>
            <img src="/assets/images/workshops/learncin_left_deco_rect.svg" width="100%" 
              title="Small white square over workshop item heading" />
          </div>
        </div>
      )}

      {contentHtml && (
        <WorkshopListItem
          id={`entry_${workshopEntryKey}_content`}
          className="content"
          dangerouslySetInnerHTML={{ __html: contentHtml }}
        />
      )}
    </ExtenderBar>
  );
};
