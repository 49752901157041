import { CONFIG } from "data";
import { Size, Vector } from "./types";

const { PROJECTION: { perspectivicProjection, projectionPlaneDistance } } = CONFIG;

export const VECTOR_ZERO: Vector = { x: 0, y: 0, z: 0 };
export const SIZE_ZERO: Size = { width: 0, height: 0 };

export const sameVectors = (v1: Vector, v2: Vector) => {
  return (
    v1.x === v2.x &&
    v1.y === v2.y &&
    v1.z === v2.z
  );
};

export const sameSizes = (s1: Size, s2: Size) => {
  return s1.width === s2.width && s1.height === s2.height;
};

export function vectorLengthSqr(v: Vector): number {
  return (
    v.x * v.x +
    v.y * v.y +
    v.z * v.z
  );
}

export function vectorLength(v: Vector): number {
  return Math.sqrt(vectorLengthSqr(v));
}

export function vectorDistance(v1: Vector, v2: Vector): number {
  return vectorLength(vectorSub(v1, v2));
}

export function vectorDistanceSqr(v1: Vector, v2: Vector): number {
  return vectorLengthSqr(vectorSub(v1, v2));
}

export function sizeDiagonalLengthSqr(s: Size): number {
  return s.width * s.width + s.height * s.height;
}

export function sizeDiagonalLength(s: Size): number {
  return Math.sqrt(sizeDiagonalLengthSqr(s));
}

export function getCenterPoint(s: Size): Vector {
  return {
    x: s.width / 2,
    y: s.height / 2,
    z: 0
  };
}

export function vectorMul(v: Vector, f: number): Vector {
  return {
    x: v.x * f,
    y: v.y * f,
    z: v.z * f
  };
}

export function vectorDiv(v: Vector, f: number): Vector {
  return {
    x: v.x / f,
    y: v.y / f,
    z: v.z / f
  };
}

export function vectorNormalized(v: Vector, resultLength = 1): Vector{
  return vectorMul(v, resultLength / vectorLength(v));
}

export function vectorAdd(v1: Vector, v2: Vector): Vector {
  return {
    x: v1.x + v2.x,
    y: v1.y + v2.y,
    z: v1.z + v2.z
  };
}

export function vectorSub(v1: Vector, v2: Vector): Vector {
  return {
    x: v1.x - v2.x,
    y: v1.y - v2.y,
    z: v1.z - v2.z
  };
}

export function vectorDotProduct(v1: Vector, v2: Vector): Vector{
  return {
    x: v1.x * v2.x,
    y: v1.y * v2.y,
    z: v1.z * v2.z
  };
}

// sets components to zero if less than threshold
export function vectorCut(v: Vector, threshold: number): Vector {
  return {
    x: v.x < threshold && v.x > -threshold ? 0 : v.x,
    y: v.y < threshold && v.y > -threshold ? 0 : v.y,
    z: v.z < threshold && v.z > -threshold ? 0 : v.z
  };
}

// If z === D3D_PLANE_DISTANCE then the scale is uniform (1)
// For z === D3D_PLANE_DISTANCE * 2 then scale is 0.5 (it's a linear projection)
export function getProject3dTo2dScale(z: number): number {
  return projectionPlaneDistance / (z + projectionPlaneDistance);
}

// result will have z = get3dTo2dProjectionScale()
export function project3dTo2d(v: Vector, viewportCenter: Vector): Vector {
  const z = getProject3dTo2dScale(v.z);

  return perspectivicProjection && v.z !== 0
    ? {
      x: (v.x - viewportCenter.x) * z + viewportCenter.x,
      y: (v.y - viewportCenter.y) * z + viewportCenter.y,
      z
    } :
    {
      x: v.x,
      y: v.y,
      z
    };
}
