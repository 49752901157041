import * as React from "react";
import { CONTENT } from "data";
import { SeparatorLine } from "components";

const { CONTACT: { address, phone, email, compRegNo, companyName, contactNotes, vatNo } } = CONTENT;

export const Contact = () => {
  return (
    <div id="contact">
      <SeparatorLine title="contact" />

      <div className="container">
        <div className="row contact-details-row">
          <div className="col font-andele font-small pb-5 lh-sm">
            <div className="text-uppercase font-dinabpn font-large contact-details-title">
              {companyName}
            </div>

            <div className="pt-5 pb-5 contact-details-fields lh-base">
              <div>
                <span className="text-red">Address:</span>&nbsp;{address}
              </div>
              <div>
                <span className="text-red">Tel.:</span>&nbsp;
                <a href={`tel:${phone.replaceAll(" ", "")}`}>
                  <span dangerouslySetInnerHTML={{ __html: phone.replaceAll(" ", "&nbsp;") }} />
                </a>
              </div>
              <div>
                <span className="text-red">E-mail:</span>&nbsp;
                <a href={`mailto: ${email}`}>
                  {email}
                </a>
              </div>
            </div>

            <div className="contact-details-fields lh-base">
              <div>
                <span className="text-red">Eu VAT no.:</span>&nbsp;{vatNo}
              </div>
              <div>
                <span className="text-red">Comp. reg. no.:</span>&nbsp;{compRegNo}
              </div>
            </div>
          </div>
          <div className="col-4 font-large font-dinabpn text-uppercase d-flex align-items-center justify-content-center lh-sm contact-details-notes">
            <div>
              {contactNotes}
            </div>
            <div className="image">
              <img src="/assets/images/blog_footer_rect.svg" title="Red box decoration" />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};
