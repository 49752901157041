import { styled } from "styled-components";

export const BlogOverview = styled.div`
  a {
    text-decoration: none;
  }
`;

export const BlogTitle = styled.div`
  font-size: 2em;
  font-family: DinaBPN;
  color: white;

  a {
    text-decoration: none;
    color: white;
  }
`;

export const BlogDate = styled.div`
  font-size: 2em;
  font-family: DinaBPN;
  color: red;
  padding-bottom: 0.5em;
`;

export const BlogHeadline = styled.div`
  font-size: 16px; /* hardcoded, not media dependent */
`;

export const BlogThumbnail = styled.div`
  display: flex;
  flex-direction: column;

  /* red square behind the image */
  > div:first-child {
    position: relative;

    > img {
      position: absolute;
      z-index: 0;
      height: 140%;
      width: 100%;
      top: -20%;      
    }

    /* the thumbnail */
    > a {
      position: relative;
      z-index: 1;

      img {
        width: 100%;
      }
    }    
  }

  > div:nth-child(2) {
    flex-grow: 1;
  }
`;

export const BlogContent = styled.div`
  max-width: 960px;
  font-size: 16px; /* hardcoded, not media dependent */
  margin: 3em 0;
`;
