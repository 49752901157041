import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BlogContent, BlogDate, BlogOverview, BlogTitle } from '../styled';
import { concatPaths, formatDate, tryParseFloat } from 'utils';
import { useBlogEntry, useElementSize, UseElementSizeCallback } from 'hooks';
import { NavigateBackArrow } from 'components';

export interface BlogEntryDetailedProps {
  blogEntryKey: string;
}

export const BlogEntryDetailed = ({ blogEntryKey }: BlogEntryDetailedProps) => {
  const { data: blogEntry } = useBlogEntry({ blogEntryKey });
  const { key, title, date, contentHtml } = blogEntry || {};
  
  const [dynamicHeightElementIds, setDynamicHeightElementIds] = useState<string[]>([]);
 
  // set height dynamically when these elements get resized
  const handleResize: UseElementSizeCallback = (bounds, element) => {
    const contentWidth = tryParseFloat(element.getAttribute("contentWidth"));
    const contentHeight = tryParseFloat(element.getAttribute("contentHeight"));
    const contentPaddingHorizontal = tryParseFloat(element.getAttribute("contentPaddingHorizontal"));
    const contentPaddingVertical = tryParseFloat(element.getAttribute("contentPaddingVertical"));

    if (contentWidth && contentHeight) {
      const height =
        (bounds.width - (contentPaddingHorizontal || 0))
        / contentWidth * contentHeight
        + (contentPaddingVertical || 0);

      element.style.height = `${height}px`;
    }
  };

  useElementSize({
    enabled: !!dynamicHeightElementIds.length,
    elementId: dynamicHeightElementIds,
    onResize: handleResize
  });

  useEffect(
    () => {
      // Initialize
      if (blogEntry) {
          // collect elements with contentWidth/contentHeight attributes 
        window.setTimeout(
          () => {
            const dynamicHeightElements = document.querySelectorAll("*[contentWidth][contentHeight][id]");
            console.log(dynamicHeightElements);
            if (dynamicHeightElements.length) {
              setDynamicHeightElementIds(Array.from(dynamicHeightElements).map(element => element.id));
            }
          },
          1);
      }
    },
    [blogEntry]);
  
  return blogEntry && (
    <div className="container blog-entry page-view">
      <NavigateBackArrow defaultBackUrl="/blog" />
      
      {/* Headline */}
      <BlogOverview className="blog-entry-content">
        <BlogTitle>
          <Link to={concatPaths(["/blog", key!])}>
            {title}
          </Link>
        </BlogTitle>
        <BlogDate>
          {formatDate(date!)}
        </BlogDate>
      </BlogOverview>

      {/* Dynamic HTML content */}
      <BlogContent dangerouslySetInnerHTML={{ __html: contentHtml! }} />

      <NavigateBackArrow defaultBackUrl="/blog" />
    </div>
  );
};
