import * as React from "react";

export const Introduction = () => {
  return (
    <div className="text-center introduction-container container paragraph disable-text-selection">
      <div>
        <div className="text-uppercase font-andele font-medium lh-sm introduction-motto">
          <div>
            MORE THAN <span className="text-red">20 YEARS</span> OF EXPERIENCE IN BUSINESS INTELLIGENCE AND ANALYTICS
          </div>
        </div>
      </div>
    </div>
  );
};
