import { useQuery, UseQueryResult } from "react-query";
import { concatPaths } from "utils";
import { CONFIG } from "data";
import { MarqueeItem } from "components/Marquee/types";

const { TECHNOLOGIES: { basePath, rootFile, cacheDuration } } = CONFIG;

export interface UseTechnologiesProps {
  enabled?: boolean;
}

export function useTechnologies({ enabled = true }: UseTechnologiesProps = {}): UseQueryResult<MarqueeItem[]> {
  // useQuery helps to avoid simoultaneous calls and caches data
  return useQuery<MarqueeItem[]>(
    "useTechnologies",
    async () => {
      const response = await fetch(concatPaths([basePath, rootFile]));
      return await response.json();
    },
    {
      enabled,
      staleTime: cacheDuration      
    });
}
