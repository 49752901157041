import { MENU_ITEMS, SPIDER_MENU } from "data";
import { SpiderGraph } from "./types";
import { convertArrayToDictionary, createRigidbody, deg2Rad, VECTOR_ZERO } from "utils";
import { Vector } from "utils/types";

const {
  ID, FRICTION, HOME_MASS, HOME_SIZE, ITEM_MASS, ITEM1_SIZE, ITEM2_SIZE,
  HOME_NODE_ATTRACTOR_POWER, ITEM_NODE_ATTRACTOR_POWER, TARGET_POSITION_ATTRACTION_POWER,
  ITEM_RADIUS_X, ITEM_RADIUS_Y
} = SPIDER_MENU;

// spiderMenu.handleResize() will set desiredDistance in attractors
export const DEFAULT_SPIDER_MENU_GRAPH: SpiderGraph = (() => {
  const homeTargetPosition: Vector = { x: 0.5, y: 0, z: 0 };
  const initialPosition: Vector = { x: 0.5, y: 0, z: 250 };

  return {
    id: ID,
    canvasSize: {
      width: 1, // the first resize call will update it
      height: 1
    },
    nodes: convertArrayToDictionary(MENU_ITEMS, (
      { key, title, targetUrl,
        spiderMenuRadius = 1,
        spiderMenuAngleOffset = 0
      },
      index
    ) => {
      const anglePadding = 0;
      const unit = Math.PI / (MENU_ITEMS.length - 2 + anglePadding * 2); // home is not on the arc
      const angle = (index - 1 + anglePadding) * unit + deg2Rad(spiderMenuAngleOffset);

      const targetPosition: Vector = key === "home"
        ? homeTargetPosition
        : {
          x: 0.5 - Math.cos(angle) * ITEM_RADIUS_X * spiderMenuRadius,
          y: 0.5 + Math.sin(angle) * ITEM_RADIUS_Y * spiderMenuRadius,
          z: 0
        };

      return [
        key,
        {
          nodeId: key,
          title,
          targetUrl,
    
          titleAlign: (
            key === "home" ? "inside" :
              index < 3 ? "left" :
                index > MENU_ITEMS.length - 3 ? "right" :
                  "bottom"
          ) as any,
    
          size: (
            key == "home" ? HOME_SIZE :
              index % 2 ? ITEM2_SIZE :
                ITEM1_SIZE
          ),

          rigidbody: createRigidbody({
            rigidbodyId: key,
            mass: key === "home" ? HOME_MASS : ITEM_MASS,

            position: initialPosition,
            targetPosition,
            friction: FRICTION,

            attractors: [
              // target positions
              {
                type: "target-position",
                targetPosition,
                power: TARGET_POSITION_ATTRACTION_POWER
              },
              ...MENU_ITEMS.map(item => ({
                type: "node-to-node",
                targetPosition: item.key,
                power: (
                  item.key === "home" && key !== "home" ? HOME_NODE_ATTRACTOR_POWER : // home attracts all
                    key === "home" && item.key !== "home" ? ITEM_NODE_ATTRACTOR_POWER :  // all attracts home
                      0
                )
              })).filter(t => t.power),
              ...key === "home"
                // dynamic attractor for home to follow the cursor
                ? [{
                  type: "target-cursor",
                  targetPosition: VECTOR_ZERO,
                  power: 0
                }]
                : []
            ]
          })
        }
      ];
    }),
    edges: MENU_ITEMS.filter(t => t.key !== "home").map(t => ({ fromNodeId: "home", toNodeId: t.key }))
  };
})();
