import * as React from "react";

export const SERVICES = {
  MOTTO: <>WE DO MORE<br /><span className="text-red">BUSINESS<br />INTELLIGENCE</span><br />THAN EVER</>,

  ENTERPRISE_ARCHITECTURE_MODERNIZATION: {
    title: "ENTERPRISE DATA ARCHITECTURE MODERNIZATION ADVISORY AND IMPLEMENTATION",
    headline:
      "Security, scalability, reliability are no longer the goal, but only a step toward modern data warehouses, " +
      " that is required for modern reporting",
    content:
      "The new challenges include GDPR, self-service capabilities, dynamic data models, almost instant data extraction and versioning, " +
      "machine learning, data science analytics, IOT, cost optimization, realtime geospatial data processing, and so much more. " +
      "We provide advisory, design and even implement native or hybrid data warehouses or lakehouses for a vast range of small and large enterprises."
  },

  DATA_VISUALIZATION: {
    title: "DATA VISUALIZATION ADVISORY AND IMPLEMENTATION",
    headline: "Data visualizations and dashboards brings data to life.",
    content:
      "Executives and leaders are now working in data-driven companies, making informed decisions based on insights " +
      "that can be enabled by BI and technical expertise."
  },

  ETLAST: {
    headline: "Our own ETL (Extract, Transform, Load) solution",
    content:
      "Many of our clients use our in-house but open source framework called EtLast to do the heavy lifting: " +
      "move and process data in a cost efficient and unified way. " +
      "It is fully integrated with various on- premise and cloud data storage technologies, analytical platforms and ETL solutions."
  },

  SELFSERVICE_BI: {
    title: "SELF-SERVICE BI INTRODUCTION ADVISORY AND IMPLEMENTATION",
    headline: "Company-wise, intuitive and actionable dashboards",
    content:
      "The managed and curated self-service business intelligence allows business users to ask questions of their data, get insights, " +
      "and analyze data - without relying on IT, BI specialists, or SQL for the purposes of taking business actions."
  },

  WORKSHOPS: {
    title: "WORKSHOPS",
    content:
      "We are ready to organize the Power BI and Microsoft Azure workshops at your office, external site, or online (via MS Teams) " +
      "customized for your company or team. We welcome any participants from beginners to experts, moreover, according to your needs, " +
      "we deliver workshops especially for developers. We usually share our professional experience via tailor-made workshops."
  },

  WEB_APPS: {
    title: "WEB BUSINESS APPLICATION DEVELOPMENT",
    headline: "Efficient, scalable tailor-made systems",
    content:
      "Modern web frameworks enable sophisticated, user friendly, responsive web applications that support business processes at the highest level. " +
      "These solutions are becoming the norm for any web-based project for any company."
  }
};
