import * as React from "react";
import { useState } from "react";
import { MenuBarProps, MenuItem } from "./types";
import { CONFIG } from "data";
import { Link, useNavigate } from "react-router-dom";
import { navigateTo } from "utils";

const { UI: { menuBarAutoHideMs } } = CONFIG;

export const MenuBar = ({ menuType, className = "", items, children }: MenuBarProps) => {
  const [isExpanded, setIsExpanded] = useState(false); 
  const [isClosing, setIsClosing] = useState(false);
  const [timer, setTimer] = useState<number>(0);

  const navigate = useNavigate();

  const closeMenu = () => {
    if (timer) {
      window.clearTimeout(timer);
    }

    setIsClosing(true);

    setTimer(window.setTimeout(() => {
      setIsClosing(false);
      setIsExpanded(false);
    }, 250));
  };

  const openMenu = () => {
    if (timer) {
      window.clearTimeout(timer);
    }

    setIsExpanded(true);

    setTimer(window.setTimeout(
      () => closeMenu(),
      menuBarAutoHideMs
    ));
  }
  
  const handleToggleMenu = () => {
    if (isExpanded) {
      closeMenu();
    }
    else {
      openMenu();
    }
  };

  const handleMenuItemSelected = ({ targetUrl }: MenuItem) => {
    closeMenu();
    navigateTo(targetUrl, navigate);
  };

  const renderMenuItem = (item: MenuItem) => {
    const { key, title, targetUrl } = item;

    return (
      <li key={key} className="nav-item pe-4">
        <Link
          className="nav-link text-uppercase"
          to={targetUrl}
          onClick={() => handleMenuItemSelected(item)}
        >{title}</Link>
      </li>
    );
  };  

  switch (menuType) {
    case "bottom-bar":
      return (
        <div className={`bottom-menu-container ${className}`}>
          <div className="container">
            <nav className="text-uppercase navbar navbar-expand-lg">
              <div className="">
                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                  {items.map(renderMenuItem)}
                </ul>
              </div>
            </nav>
            {children}
          </div>
        </div>
      );
    
    case "top-hamburger":
      const isLogoClickable = window.location.hash !== "" && window.location.hash !== "#/";

      return (
        <div className={`container ${className}`}>
          <nav className="text-uppercase nav-container navbar navbar-expand-lg">
            <img
              src="/assets/images/logo/white_fizz_logo.svg"
              title="FizzCode logo"
              className={`navbar-logo mobile ${isLogoClickable ? "pointer" : ""}`}
              onClick={()=> isLogoClickable && navigateTo("/", navigate)}
            />
            
            <button
              className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMain"
              aria-label="Hamburger menu button to toggle navigation"
              onClick={handleToggleMenu}
            >
              <span className="navbar-dark navbar-toggler-icon"></span>
              <div id="navbarMain"
                className={`${isClosing ? "navbar-menu-collapse-animation" : isExpanded ? "navbar-menu-expand-animation" : "collapse"} navbar-collapse`}
              >
                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                  {items.map(renderMenuItem)}
                </ul>
              </div>
            </button>
          </nav>
          {children}
        </div>
      );
    
    // top-bar (normal, not hamburger)
    default:
      return (
        <div className={`container ${className}`}>
          <nav className="text-uppercase nav-container navbar navbar-expand-lg">
            <div id="navbarMain" className="navbar-collapse">
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                {items.map(renderMenuItem)}
              </ul>
            </div>
          </nav>
          {children}
        </div>
      );
  }
};
