import { styled } from "styled-components";

export const ExtenderBarHeader = styled.div<{
  $isHighlighted?: boolean;
}>`
  display: flex;
  flex-direction: row;
  font-family: DinaBPN;
  position: relative;  

  > div:first-child {
    background-color: red;   
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.25em;
    width: 1em;
    justify-content: end;

    ${({ $isHighlighted: $isExpanded }) => $isExpanded
    ? `
      position: absolute;
      left: -50vw;
      width: calc(50vw + 1em + 2px);
      height: 100%;
    `
    : `
    `}
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em 3em;

    ${({ $isHighlighted: $isExpanded }) => $isExpanded
    ? `    
      background-color: red;
      border-top: solid 1px red;
      border-bottom: solid 1px red;
      border-right: solid 1px red;      

      > div:last-child {
        color: black;
      }

      position: relative;
      left: calc(2em + 3px);
    `
    : `
      border-top: solid 1px white;
      border-bottom: solid 1px white;
      border-right: solid 1px white;

      > div:last-child {
        color: red;
      }
    `}

    > div:first-child {
      color: white;
    }
  }
`;
