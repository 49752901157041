import { eliminateNaN, isString } from "utils";
import { Rigidbody, RigidbodyAttractor, SimulationStepOptions, Size, Vector } from "./types";
import { VECTOR_ZERO, vectorAdd, vectorCut, vectorDiv, vectorLength, vectorMul, vectorSub } from "./vector";

export function createRigidbody(props: Pick<Rigidbody, "rigidbodyId" | "position" | "mass"> & Partial<Rigidbody>): Rigidbody {
  return {
    velocity: VECTOR_ZERO,
    friction: 0,
    attractors: [],

    // override defaults
    ...props
  };
}

// map positions from old size to new size
export function scaleRigidbody(obj: Rigidbody, newSize: Size, oldSize: Size): Rigidbody {  
  const mapVector = (v: Vector) => ({
    x: v.x * newSize.width / oldSize.width,
    y: v.y * newSize.height / oldSize.height,
    z: v.z
  });

  return {
    ...obj,
    position: mapVector(obj.position),
    velocity: mapVector(obj.velocity),
    targetPosition: obj.targetPosition && mapVector(obj.targetPosition),

    attractors: obj.attractors.map(attractor => ({
      ...attractor,
      targetPosition: isString(attractor.targetPosition)
        ? attractor.targetPosition
        : mapVector(attractor.targetPosition)
    }))
  };
}

// updates velocity
export function addForce(f1: Vector, f2: Vector, mass: number): Vector {
  return vectorAdd(f1, vectorDiv(f2, mass));
}

export function simulationStep<Node extends Rigidbody>(
  obj: Node,
  { simulationStepMs, minMovementThreshold, getRigidbodyById }: SimulationStepOptions
): Node {
  if (obj.locked) {
    return obj;
  }

  const { position, mass, friction, attractors } = obj;
  let sumForce = VECTOR_ZERO;

  for (const attractor of attractors) {
    if (attractor.power) {
      const attractorPosition = getResolvedAttractorTargetPosition(attractor, getRigidbodyById);

      if (attractorPosition) {
        const targetVector = vectorSub(attractorPosition, position);
        const distance = vectorLength(targetVector);

        sumForce = addForce(
          sumForce,
          attractor.desiredDistance === undefined || distance <= attractor.desiredDistance
            ? vectorMul(targetVector, attractor.power)
            : vectorMul(
              targetVector,
              eliminateNaN(
                attractor.power
                // when distance is attractor.desiredDistance then this value is 1, but grows squarely
                * Math.pow(distance, 4)
                / Math.pow(attractor.desiredDistance, 4),
                attractor.power)
            ),
          mass);
      }
    }
  }

  const velocity = vectorCut(
    vectorMul(vectorAdd(obj.velocity, sumForce), 1 - friction),
    minMovementThreshold);

  return {
    ...obj,
    velocity,
    position: vectorAdd(position, vectorMul(velocity, simulationStepMs / 1000))
  };
}

export function getResolvedAttractorTargetPosition(
  attractor: RigidbodyAttractor,
  getRigidbodyById: (rigidbodyId: string) => Rigidbody | undefined
): Vector | undefined {
  return isString(attractor.targetPosition)
    ? getRigidbodyById(attractor.targetPosition)?.position
    : attractor.targetPosition;
}
