import React from "react";

export interface SeparatorLineProps {
  title: string;
  titleSuffix?: string;
  noContainer?: boolean;
}

export const SeparatorLine = ({ title, titleSuffix, noContainer }: SeparatorLineProps) => {
  const content = (
    <div className="row">
      {title && (
        <div className="col-auto font-xsmall font-andele" >
          {title}&nbsp;{titleSuffix && <span className="text-red">{titleSuffix}</span>}
        </div>
      )}
      <div className="col col-sm col-md-6 d-flex flex-column">
        <hr className="topic-separator mt-auto mb-auto" style={{ position: "relative", top: 1 }} />
      </div>
    </div>
  );

  return noContainer
    ? content
    : (
      <div className="container separator-line-container">
        {content}
      </div>
    );
};
