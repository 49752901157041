import { useQuery, UseQueryResult } from "react-query";
import { concatPaths } from "utils";
import { CONFIG } from "data";
import { WorkshopEntry } from "pages/Workshops/types";

const { WORKSHOPS: { basePath, rootFile, cacheDuration } } = CONFIG;

export interface UseWorkshopeEntriesProps {
  enabled?: boolean;
}

export function useWorkshopEntries({ enabled = true }: UseWorkshopeEntriesProps = {}): UseQueryResult<WorkshopEntry[]> {
  // useQuery helps to avoid simoultaneous calls and caches data
  return useQuery<WorkshopEntry[]>(
    "useWorkshopEntries",
    async () => {
      const response = await fetch(concatPaths([basePath, rootFile]));
      return await response.json();
    },
    {
      enabled,
      staleTime: cacheDuration      
    });
}
