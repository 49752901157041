import * as React from "react";

export const CONTENT = {
  CONTACT: {
    companyName: <>FizzCode Limited&nbsp;CO.</>,
    address: <>42&nbsp;Vada&#769;sz&nbsp;utca Budapest,&nbsp;<span className="text-nowrap">H-1054</span></>,
    phone: "+36 30 498 7957",
    email: "info@fizzcode.hu",
    vatNo: "HU25518401",
    compRegNo: "01-09-279771",
    contactNotes: <>WE ARE HAPPY TO GET IN CONTACT WITH YOU, WE <span className="text-red">REPLY WITHIN 24 HOURS.</span></>
  },

  COPYRIGHT: <>&copy; 2024 FizzCode</>
};
