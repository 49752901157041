import { useQuery, UseQueryResult } from "react-query";
import { concatPaths } from "utils";
import { useBlogEntries } from "./useBlogEntries";
import { BlogEntryWithHtmlContent } from "pages/Blog/types";
import { CONFIG } from "data";
import { useMemo } from "react";

const { BLOG: { basePath, cacheDuration } } = CONFIG;

export interface UseBlogEntryProps {
  blogEntryKey: string | undefined;
  enabled?: boolean; // default is 'true'
}

// Returns blog entry with HTML content fetched from file
export function useBlogEntry({ blogEntryKey, enabled = true }: UseBlogEntryProps): UseQueryResult<BlogEntryWithHtmlContent> {
  const { data: blogs } = useBlogEntries({ enabled });
  const blog = blogEntryKey && blogs && blogs.find(t => t.key === blogEntryKey) || undefined;
  
  // useQuery helps to avoid simoultaneous calls and caches data
  const queryResult = useQuery(
    `useBlogEntry: ${blogEntryKey}`,
    async () => {
      if (blogEntryKey && blog) {
        const response = await fetch(concatPaths([basePath, "html", blog.contentUrl]));
        return await response.text();
      }
      else {
        return undefined;
      }
    },
    {      
      enabled: enabled && !!blog,
      staleTime: cacheDuration      
    });

  // we need to return a memoized reference, but we have a calculated object, so we need to store it
  return useMemo<UseQueryResult<BlogEntryWithHtmlContent>>(
    () => ({
      ...queryResult,
      // @ts-ignore
      data: blog && { ...blog, contentHtml: queryResult.data }
    }),
    [blog, queryResult]
  );
}
