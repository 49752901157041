import * as React from "react";
import { CSSProperties } from "styled-components";
import { Vector } from "utils/types";

export interface SvgLineProps {
  points: Array<Vector & { color?: CSSProperties["color"] }>;
  color: CSSProperties["color"];
}

export const SvgLine = ({ points, color: defaultColor }: SvgLineProps) => {
  const minX = Math.min(...points.map(t => t.x));
  const maxX = Math.max(...points.map(t => t.x));
  const minY = Math.min(...points.map(t => t.y));
  const maxY = Math.max(...points.map(t => t.y));
  const width = maxX - minX;
  const height = maxY - minY;

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        left: minX,
        top: minY,
        width,
        height
      }}
    >
      {points.map(({ x, y, color }, index) => (
        index < points.length - 1
          ? (
            <line
              key={index}
              x1={x - minX}
              y1={y - minY}
              x2={points[index + 1].x - minX}
              y2={points[index + 1].y - minY}
              stroke={color || defaultColor}
            />
          )
          : null
      ))}
    </svg>
  );
};
