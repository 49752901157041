import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { BlogEntryPage, BlogPage, HomePage, WorkshopsPage } from 'pages';
import { concatPaths } from 'utils';
import { useBlogEntries } from 'hooks';

export const AppRoutes = () => {
  const { data: blogEntries } = useBlogEntries();

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />}></Route>

        {/* HomePage */}
        <Route path="/services" element={<HomePage />}></Route>
        <Route path="/weare" element={<HomePage />}></Route>
        <Route path="/technologies" element={<HomePage />}></Route>
        <Route path="/partners" element={<HomePage />}></Route>
        <Route path="/contact" element={<HomePage />}></Route>

        <Route path="/blog" element={<BlogPage />}></Route>
        <Route path="/workshops" element={<WorkshopsPage />}></Route>

        {blogEntries && blogEntries.map(({ key }) => (
          <Route key={key} path={concatPaths(["/blog", key])} element={<BlogEntryPage blogEntryKey={key} />}></Route>
        ))}
      </Routes>
    </Router>
  );
};
