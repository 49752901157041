import * as React from "react";
import { MENU_ITEMS } from "data";
import { MenuBar } from "components";
import { PropsWithClassName } from "utils/types";

export interface TopMenuProps {
  desktopMenu?: boolean; // default is true
  mobileMenu?: boolean; // default is true
}

export const TopMenu = ({ desktopMenu = true, mobileMenu = true }: TopMenuProps) => {
  return (
    <>
      {desktopMenu && <MenuBar menuType="top-bar" className="desktop" items={MENU_ITEMS} />}
      {mobileMenu && <MenuBar menuType="top-hamburger" className="mobile" items={MENU_ITEMS} />}
    </>
  );
};
