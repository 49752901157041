import React, { PropsWithChildren, useEffect } from 'react';
import { AnimatedLogo } from 'components';
import { BottomMenu, Contact, Partners, Technologies, TechnologiesProps, TopMenu, TopMenuProps } from './sections';
import { scrollToAnchor } from 'utils';
import { useScrollToTop } from 'hooks';

const iObserver = new IntersectionObserver(function (entries) {
  entries.forEach(function (entry) {
    if (entry.isIntersecting
      && !entry.target.classList.contains('animate__animated')) {
      const animationClasses: string[] = [];

      entry.target.classList.forEach(cclass => {
        if (cclass.indexOf('animate__') >= 0) {
          animationClasses.push(cclass);
          entry.target.classList.remove(cclass);
        }
      });

      setTimeout(function () {
        entry.target.classList.add('animate__animated');
        animationClasses.forEach(cclass => entry.target.classList.add(cclass));
      }, 50);
    }
  });
});

export interface MainLayoutProps extends PropsWithChildren {
  topMenuProps?: TopMenuProps;
  technologiesProps?: TechnologiesProps;
}

export const MainLayout = ({ topMenuProps, technologiesProps, children }: MainLayoutProps) => {
  useScrollToTop();
  
  useEffect(
    () => {
      // Initialize animations
      const animatedElements = document.querySelectorAll('.animated');

      if (animatedElements) {
        animatedElements.forEach(function (element) { return iObserver.observe(element); });
      }

      scrollToAnchor();
    },
    []
  );

  // should return <div/> but we must respect the original layout (single div under body)
  return (
    <>
      <AnimatedLogo className="desktop" />
      <TopMenu {...topMenuProps} />

      {children}

      <Technologies {...technologiesProps} />
      <Partners />
      <Contact />
      <BottomMenu />
    </>
  );
};
