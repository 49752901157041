import * as React from "react";
import { OurServices_DesktopView } from "./DesktopView";
import { OurServices_MobileView } from "./MobileView";

export const OurServices = () => {
  return (
    <div id="services">
      <OurServices_DesktopView />
      <OurServices_MobileView />
      
      <div className="red-triangle">
        <svg viewBox="0 0 1920 338">
          <polygon points="0,338 1920,0 1920,338"></polygon>
        </svg>
      </div>
    </div>
  );
};
