import * as React from "react";
import Lottie from 'react-lottie';
import * as animationData from './animated-logo.json';
import { PropsWithClassName } from "utils/types";

export type AnimatedLogoProps = PropsWithClassName;

export const AnimatedLogo = ({ className = "" }: AnimatedLogoProps) => {
  return (
    <div className={`animated-logo-container pe-none ${className}`}>
      <div id="animated-logo" className="logo">
        <Lottie
          isClickToPauseDisabled
          options={{
            loop: true,
            autoplay: true,
            animationData
          }} />
      </div>
    </div>
  );
};
