import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { spiderGraphSimulationStep, scaleSpiderGraph, updateSpiderGraphNodes } from "./functions";
import {
  SIZE_ZERO, VECTOR_ZERO, addForce, getCurrentTime, getResolvedPointerPagePosition, isTouchEvent, navigateTo, random, sameSizes,
  traceEvents, vectorAdd, vectorSub
} from "utils";
import { DEFAULT_SPIDER_MENU_GRAPH } from "./data";
import { CONFIG, SPIDER_MENU } from "data";
import { SpiderGraph, SpiderGraphNode } from "./types";
import { useNavigate } from "react-router-dom";
import { PropsWithClassName, Vector } from "utils/types";
import { renderSpiderGraphEdges, renderSpiderGraphNodes } from "./renderers";

const {
  ID, SIMULATION_STEP_MS, CLICK_DURATION_MS, HEARTBEAT_INTERVAL_MS, HEARTBEAT_POWER, POINTER_POKE_POWER,
  POINTER_CURSOR_TRACKING_POWER, HEARTBEAT_INTERVAL_NODE_LAG_MS: HEARTBEAT_INTERVAL_NODE_LAG
} = SPIDER_MENU;

const { TRACE_SIMULATION_DURATION } = CONFIG;

export type SpiderMenuProps = PropsWithClassName;

export const SpiderMenu = ({ className = "" }: SpiderMenuProps) => {
  const [graph, _setGraph] = useState<SpiderGraph | undefined>();
  const [canvasSize, setCanvasSize] = useState(SIZE_ZERO);
  const [isLoaded, setIsLoaded] = useState(false);

  const [draggedNode, setDraggedNode] = useState<{
    readonly node: SpiderGraphNode;
    readonly nodeElement: HTMLElement;
    readonly startElementPosition: Vector;
    readonly startCursorPosition: Vector;
    readonly startTime: number;
    readonly simulationTimer: number; // it seems the main simulationTimer stops working while dragging, we start a local one here
  } | undefined>();

  // to avoid bouncing over stationary cursor
  const [nextHeartbeatTime, setNextHeartbeatTime] = useState({
    time: HEARTBEAT_INTERVAL_MS.max,
    power: HEARTBEAT_POWER.max,
    lag: HEARTBEAT_INTERVAL_NODE_LAG.max,
    nodeIndex: 0
  });

  const graphRef = useRef(graph);
  graphRef.current = graph;

  const draggedNodeRef = useRef(draggedNode);
  draggedNodeRef.current = draggedNode;

  const nextHeartbeatTimeRef = useRef(nextHeartbeatTime);
  nextHeartbeatTimeRef.current = nextHeartbeatTime;

  // set graph and graphRef.current so in the current render loop we can update the graph simoultaneously from multiple event handlers
  const setGraph = (graph: SpiderGraph) => {
    graphRef.current = graph;
    _setGraph(graph);
  };

  const navigate = useNavigate();

  const handleNavigateTo = useCallback(
    (targetUrl: string, startTime?: number) => {
      if (startTime === undefined || (getCurrentTime() - startTime) <= CLICK_DURATION_MS) {
        navigateTo(targetUrl, navigate);
      }
    },
    [navigate]);

  const handleSimulationStep = useCallback(
    () => {
      if (graphRef.current) {
        // simulate
        let newGraph = spiderGraphSimulationStep(graphRef.current);
        const currentTime = getCurrentTime();

        // generate heartbeat
        if (currentTime >= nextHeartbeatTimeRef.current.time) {
          const { nodeIndex, power, lag } = nextHeartbeatTimeRef.current;
          const nodeCount = Object.keys(graphRef.current.nodes).length;

          // skip when dragging
          if (!draggedNodeRef.current) {
            // const center = getCenterPoint(newGraph.canvasSize);
            newGraph = updateSpiderGraphNodes(newGraph, (node, index) =>
              index === nodeIndex
                ? ({
                  ...node,
                  rigidbody: {
                    ...node.rigidbody,
                    velocity: addForce(
                      node.rigidbody.velocity,
                      // vectorMul(vectorSub(node.rigidbody.position, center), power),
                      { x: 0, y: 0, z: -power * 100 },
                      1 // node.rigidbody.mass
                    )
                  }
                })
                : node
            );
          }

          // set next heartbeat time
          const lastNode = nodeIndex >= nodeCount - 1;

          setNextHeartbeatTime({
            time: currentTime + (lastNode ? random(HEARTBEAT_INTERVAL_MS) : lag),
            power: lastNode ? random(HEARTBEAT_POWER) : power,
            lag: lastNode ? random(HEARTBEAT_INTERVAL_NODE_LAG) : lag,
            nodeIndex: (nodeIndex + 1) % nodeCount
          });
        }

        setGraph(newGraph);
      }
    },
    // keep it empty (except other callbacks)
    []);

  const handleResize = useCallback(
    () => {
      traceEvents("[SpiderMenu] resize");

      const size = (document.getElementById(ID) as HTMLElement)?.getBoundingClientRect?.();

      if (size && !sameSizes(canvasSize, size)) {
        setCanvasSize(size);
        setGraph(scaleSpiderGraph(graphRef.current || DEFAULT_SPIDER_MENU_GRAPH, size));
      }
    },
    // keep it empty (except other callbacks)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
  
  const handleMouseEnter = useCallback(
    (node: SpiderGraphNode, nodeElementId: string, evt: React.MouseEvent) => {
      traceEvents("[SpiderMenu] mouseEnter", { node, nodeElementId, evt });
      const bounds = document.getElementById(nodeElementId)?.getBoundingClientRect?.();

      if (bounds && !draggedNodeRef.current && graphRef.current) {
        // manually poke nodes
        // const cursorPosition: Vector = { x: evt.clientX, y: evt.clientY, z: 0 };
        // const nodePos = { x: bounds.x, y: bounds.y, z: 0 };
        setGraph(updateSpiderGraphNodes(graphRef.current, t => (
          node.nodeId === t.nodeId
            ? {
              ...t,
              rigidbody: {
                ...t.rigidbody,
                velocity: addForce(
                  t.rigidbody.velocity,
                  // vectorMul(vectorSub(nodePos, cursorPosition), POINTER_POKE_POWER),
                  // push into the camera to zoom it out
                  { x: 0, y: 0, z: -POINTER_POKE_POWER * 100 },
                  1) // mass is constant here
              }
            }
            : t
        )));
      }
    },
    // keep it empty (except other callbacks)
    []);

  const setTargetCursorAttractorForHome = useCallback(
    (position: Vector | undefined) => {
      if (graphRef.current) {
        setGraph(updateSpiderGraphNodes(graphRef.current, node =>
          node.nodeId === "home"
            ? ({
              ...node,
              rigidbody: {
                ...node.rigidbody,
                attractors: node.rigidbody.attractors.map(attractor =>
                  attractor.type === "target-cursor"
                    ? ({
                      ...attractor,
                      targetPosition: position || VECTOR_ZERO,
                      power: position ? POINTER_CURSOR_TRACKING_POWER : 0
                    })
                    : attractor
                )
              }
            })
            : node
        ));
      }
    },
    []);

  const handleMouseDown = useCallback(
    (
      node: SpiderGraphNode,
      nodeElementId: string,
      evt: React.MouseEvent | React.TouchEvent
    ) => {
      const cursorPosition = getResolvedPointerPagePosition(evt);
      traceEvents("[SpiderMenu] mouseDown", { node, nodeElementId, evt, cursorPosition });

      if (!draggedNodeRef.current && graphRef.current && cursorPosition) {
        const nodeElement = document.getElementById(nodeElementId) as HTMLElement;

        if (nodeElement) {
          setTargetCursorAttractorForHome(undefined);

          setDraggedNode({
            node,
            nodeElement,
            startElementPosition: { x: nodeElement.offsetLeft, y: nodeElement.offsetTop, z: 0 },
            startCursorPosition: cursorPosition,
            startTime: getCurrentTime(),

            // while dragging elements the global setInterval() is not running, 
            // we start a local here for the duration of the dragging
            simulationTimer: window.setInterval(handleSimulationStep, SIMULATION_STEP_MS)            
          });
        }
      }
    },
    [handleSimulationStep, setTargetCursorAttractorForHome]);

  const handleMouseMove = useCallback(    
    (evt: MouseEvent | TouchEvent) => {
      const cursorPosition = getResolvedPointerPagePosition(evt);
      const currentTime = getCurrentTime();

      traceEvents("[SpiderMenu] mouseMove", { evt, cursorPosition, currentTime });
      
      if (graphRef.current && cursorPosition) {
        if (draggedNodeRef.current) {
          // drag node
          evt.preventDefault();
          evt.stopPropagation();

          const { node: { nodeId }, startCursorPosition, startElementPosition } = draggedNodeRef.current;
          const position = vectorAdd(startElementPosition, vectorSub(cursorPosition, startCursorPosition));

          setGraph(updateSpiderGraphNodes(graphRef.current, node => {
            return node.nodeId === nodeId
              ? {
                ...node,
                rigidbody: {
                  ...node.rigidbody,
                  position,
                  locked: true
                }
              }
              : node
          }));
        }
        else if (!isTouchEvent(evt)) {
          // follow the cursor
          const canvasBounds = document.getElementById(ID)?.getBoundingClientRect?.(); // where the node is now
          const targetPosition = graphRef.current.nodes["home"].rigidbody.targetPosition; // where the node rests

          if (canvasBounds && targetPosition) {
            setTargetCursorAttractorForHome({
              x: cursorPosition.x - canvasBounds.x,

              // don't follow the cursor if the home node is scrolled out of the screen (that would generate way to big force)
              y: canvasBounds.y >= 0
                ? cursorPosition.y - canvasBounds.y
                : targetPosition.y,
              z: 0
            });
          }
        }
      }
    },
    // keep it empty (except other callbacks)
    [setTargetCursorAttractorForHome]);
    
  const handleMouseUp = useCallback(    
    (evt: MouseEvent | TouchEvent) => {
      traceEvents("[SpiderMenu] mouseUp");

      if (graphRef.current && draggedNodeRef.current) {
        const { node: { nodeId, targetUrl }, startTime, simulationTimer } = draggedNodeRef.current;

        setTargetCursorAttractorForHome(undefined);

        setGraph(updateSpiderGraphNodes(graphRef.current, node => {
          return node.nodeId === nodeId
            ? {
              ...node,
              rigidbody: {
                ...node.rigidbody,
                locked: false
              }
            }
            : node
        }));

        window.clearInterval(simulationTimer);
        setDraggedNode(undefined);

        if (targetUrl) {
          handleNavigateTo(targetUrl, startTime);

          if (targetUrl === "/") {
            window.setTimeout(() => window.scrollTo(0, 0), 1);
          }
        }
      }
    },
    // keep it empty (except other callbacks)
    [handleNavigateTo, setTargetCursorAttractorForHome]);

  useEffect(
    () => {
      traceEvents("[SpiderGraph] INITIALIZE");

      // Initialize
      window.addEventListener("resize", handleResize);
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);

      window.addEventListener("touchmove", handleMouseMove);
      window.addEventListener("touchend", handleMouseUp);

      handleResize();

      const simulationTimer = window.setInterval(handleSimulationStep, SIMULATION_STEP_MS);
      window.setTimeout(handleResize, 1000); // this is a cosmetics glitch fix

      setIsLoaded(true);

      return () => {
        // Finalize
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);

        window.removeEventListener("touchmove", handleMouseMove);
        window.removeEventListener("touchend", handleMouseUp);

        window.clearInterval(simulationTimer);
      };
    },
    [handleResize, handleMouseMove, handleMouseUp, handleSimulationStep]);

  return (
    <div className={`container ${className}`}>
      <div id={ID} className="spiderMenu disable-text-selection">
        {graph && isLoaded && [
          renderSpiderGraphNodes(
            graph,
            {
              draggedNodeId: draggedNode?.node?.nodeId,

              rectAttributes: (node, nodeElementId) => ({
                onMouseDown: e => handleMouseDown(node, nodeElementId, e),
                onTouchStart: e => handleMouseDown(node, nodeElementId, e),
                onMouseEnter: e => handleMouseEnter(node, nodeElementId, e)
              }),
              
              titleAttributes: node => ({
                onClick: () => node.titleAlign !== "inside" && node.targetUrl && handleNavigateTo(node.targetUrl)
              })
            }),
          renderSpiderGraphEdges(graph)
        ]}
      </div>
      {TRACE_SIMULATION_DURATION && <div id={`${ID}_simulationDuration`} />}
    </div>
  );
};
