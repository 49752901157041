import React from 'react';
import { AppRoutes } from 'components/AppRoutes';
import { QueryClient, QueryClientProvider } from 'react-query';

export const App = () => {
  // AppWrapper renders:
  // - HelmetProvider
  // - QueryClientProvider
  // - Styled.GlobalStyles

  return (
    <QueryClientProvider client={new QueryClient()}>
      <AppRoutes />
    </QueryClientProvider>
  );
};
