import * as React from "react";
import { Marquee, SeparatorLine } from "components";
import { useTechnologies } from "hooks";
import { CONFIG } from "data";
import { concatPaths } from "utils";

const { TECHNOLOGIES: { basePath } } = CONFIG;

export interface TechnologiesProps {
  showHeading?: boolean; // default is 'true'
}

export const Technologies = ({ showHeading = true }: TechnologiesProps) => {
  const { data: technologies } = useTechnologies();

  return (
    <div id="technologies" className="technologies-container">
      {showHeading &&
        <div className="container">
          <SeparatorLine title="our stack" noContainer />

          <div className="row">
            <div>
              <div className="d-flex justify-content-center">
                <div className="techlogos-header text-uppercase font-dinabpn font-xlarge lh-sm">
                  WE HAVE EXTENSIVE EXPERIENCE WITH DATA TECHNOLOGIES
                </div>
              </div>

              <div className="text-center text-uppercase font-dinabpn font-large lh-sm text-gray pt-5">
                TO GIVE YOU OUR KNOWLEDGE FOR THE BEST RESULT
              </div>
            </div>
          </div>
        </div>
      }
      
      <div className="techlogos">
        <div className="techlogos-container">
          <Marquee
            logoBaseUrl={concatPaths([basePath, "images"])}
            items={technologies || []} className="marquee--reverse marquee--no-top-margin"
          />
        </div>
      </div>
    </div>
  );
};
