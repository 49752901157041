import { useQuery, UseQueryResult } from "react-query";
import { concatPaths } from "utils";
import { CONFIG } from "data";
import { MarqueeItem } from "components/Marquee/types";

const { PARTNERS: { basePath, rootFile, cacheDuration } } = CONFIG;

export interface UsePartnersBlog {
  enabled?: boolean;
}

export function usePartners({ enabled = true }: UsePartnersBlog = {}): UseQueryResult<MarqueeItem[]> {
  // useQuery helps to avoid simoultaneous calls and caches data
  return useQuery<MarqueeItem[]>(
    "usePartners",
    async () => {
      const response = await fetch(concatPaths([basePath, rootFile]));
      return await response.json();
    },
    {
      enabled,
      staleTime: cacheDuration      
    });
}
